import { Injectable } from '@angular/core'
import { Router, UrlTree } from '@angular/router'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'

import { EconomyTabState, UserPermissions } from '../../module/dashboard/tabs/economy-tab/states/economy-tab.state'

@Injectable()
export class PaymentsGuard {
  @SelectSnapshot(EconomyTabState.userEconomyPermissions) userEconomyPermissions: UserPermissions

  constructor(private router: Router) {}

  canActivate(): UrlTree {
    if (this.userEconomyPermissions.membershipFee.canView) {
      return this.router.parseUrl('dashboard/economy/payments/membership-fees')
    } else if (this.userEconomyPermissions.trainingFee.canView) {
      return this.router.parseUrl('dashboard/economy/payments/training-fees')
    } else {
      return this.router.parseUrl('dashboard/economy/payments/other')
    }
  }
}
